import '../styles/global.scss';
import './components/menu.js';
import './components/footer-accordion';
import './components/header-mob-accordion';
import './components/cookies';
import './components/bottom-navbar';
import ScreenSizeDetector from 'screen-size-detector';

const headerWrapper = document.querySelector('.header__wrapper');
const header = document.querySelector('.header');

const makeHeaderFixed = function (e) {
  headerWrapper.style.position = 'fixed';
  headerWrapper.style.boxShadow = '0px 3px 6px rgba(0, 0, 0, 0.16)';
  headerWrapper.style.top = '0';
  if (window.scrollY < 3)
    headerWrapper.style.boxShadow = 'none';
  headerWrapper.style.top = '-5px';
  header.style.marginTop = '61px';
}

const defaultOptions = {
  widthDefinitions: { // Width definitions object. Can be overwritten, added or removed
    tablet: {
      min: 0,
      max: 768,
      inclusion: "[]",
      onEnter: () => {
        window.addEventListener('scroll', makeHeaderFixed);
      },
      onLeave: () => {
        window.removeEventListener('scroll', makeHeaderFixed);
        headerWrapper.style.position = 'relative';
        headerWrapper.style.boxShadow = 'none';
        header.style.marginTop = '0';
      },
    },
  },
};

new ScreenSizeDetector(defaultOptions);

function getDomain() {
  const hostnameArray = window.location.hostname.split('.')
  const numberOfSubdomains = hostnameArray.length - 2
  return hostnameArray.length === 2 ? window.location.hostname : hostnameArray.slice(numberOfSubdomains).join('.')
}

// window.addEventListener("beforeunload", function(e) {
//   document.cookie = `origin=${window.location.href}; path=/; domain=${getDomain()};`
// }); 

window.addEventListener("pagehide", function (e) {
  document.cookie = `origin=${window.location.href}; path=/; domain=${getDomain()};`
});

const seoText = document.querySelector('.seo-text');

if (seoText) {
  const btn = document.querySelector('.seo-text__show-more')
  btn.addEventListener('click', () => {
    seoText.classList.add('seo-text_full');
  })
}